// On peut se hooker sur divers divers évènenements de filter posts, dans lesquels event.target = le conteneur
// thisEl.addEventListener( 'issbeforeupdate' ) - après call AJAX, enfants parsés, pas ajoutés au DOM
// thisEl.addEventListener( 'issafterupdate' ) - après call AJAX, enfants ajoutés au DOM
// thisEl.addEventListener( 'issnoresult' ) - Les filtres n'ont retournés aucun résultat
// unItem.addEventListener( 'issitemanimated' ) - pour chaque élément animé

// Note : Librement inspiré du script infinite-scroll-simple.js

// Utiliser ce polyfill si besoin d'IE 11
// import 'unfetch/polyfill';
// Babel a besoin de ça pour les fonctions async
import "regenerator-runtime/runtime";
// Pour les divers sizes d'images
import imgCheck from "img-check.js";
import * as nubeeLoading from 'nubee-loading.js';

function FilterPosts( args ) {
	let that = this;

	let beforeUpdateEvent = new Event( 'issbeforeupdate' );
	let afterUpdateEvent = new Event( 'issafterupdate' );
	let noMoreEvent = new Event( 'issnoresult' );
	let itemAnimatedEvent = new Event( 'issitemanimated' );

	args = args || {};

	args.form = args.form || null;// Le formulaire qui contient tous les filtres
	args.container = args.container || null;// Le container qui va recevoir les articles

	args.instanceIdAttr = args.instanceIdAttr || 'data-iss-instance-id';
	args.loadingClass = args.loadingClass || 'iss-loading';
	args.noMoreClass = args.noMoreClass || 'iss-no-more';
	args.itemSel = args.itemSel || '.iss-item';
	args.searchButton = args.searchButton || '.search-button';
	args.resetButton = args.resetButton || '.reset-button';
	args.buttonSel = args.buttonSel || '.iss-button';
	args.checkboxSel = args.checkboxSel || '.filter-checkbox';
	args.animatingInClass = args.animatingInClass || 'iss-animating-in';
	args.animatedInClass = args.animatedInClass || 'iss-animated-in';
	args.containerInnerSel = args.containerInnerSel || '.iss-container-inner';
	args.animate = args.animate || false;
	args.useInner = args.useInner || true;
	args.debug = args.debug || false;

	this.args = args;

	this.init = function() {
		if( that.args.container && that.args.container instanceof HTMLElement ) {
			that.container = that.args.container;
			if( that.args.debug ) {
				console.log( "FilterPosts: container set to:" );
				console.log( that.container );
			}
		} else {
			console.error( "FilterPosts: args.container must be set and be an HTMLElement." );
			return;
		}

		if( that.args.form && that.args.form instanceof HTMLElement ) {
			that.form = that.args.form;
			if( that.args.debug ) {
				console.log( "FilterPosts: form set to:" );
				console.log( that.form );
			}
		} else {
			console.error( "FilterPosts: args.form must be set and be an HTMLElement." );
			return;
		}

		/*that.instanceId = that.container.getAttribute( that.args.instanceIdAttr ) || null;

		if( that.args.debug && that.instanceId ) {
			console.warn( "FilterPosts: You set an instance ID of " + that.instanceId + " to your container. This makes the script heavier to run. Only do this if your page has several different instances." );
			console.log( "FilterPosts: Make sure your instance ID is set to every one of those elements: container, button, iss-element." );
		}*/

		// that.button = that.getKeyElement( 'button' );

		if( that.args.useInner ) {
			that.containerInner = that.getKeyElement( 'containerInner' );
			that.appendEl = that.containerInner;
		} else {
			that.appendEl = that.container;
		}

		if( that.args.debug ) {
			if( that.args.useInner ) {
				console.log( "FilterPosts: Using an inner container." );
				console.log( "FilterPosts: containerInner set to:" );
				console.log( that.containerInner );
			}
			// console.log( "FilterPosts: button set to:" );
			// console.log( that.button );
		}

		that.domParser = new DOMParser();
		that.loading = false;
		that.noMore = false;

		that.addEventListeners();
	};

	this.addInstanceIdTo = function( inputSel ) {
		let outputSel = inputSel;

		if( that.instanceId ) {
			outputSel += '[' + that.args.instanceIdAttr + '="' + that.instanceId + '"]';
		}

		return outputSel;
	};

	this.getKeyElement = function( keyElType, source, initial ) {
		source = source || document;
		initial = 'undefined' != typeof initial ? initial : true;

		let keyEl = null;
		let keyEls = null;
		let theSel;

		switch( keyElType ) {
			case 'containerInner':
				theSel = that.args.containerInnerSel;
			break;
			case 'checkbox':
				theSel = that.args.checkboxSel;
			break;
			case 'button':
				theSel = that.args.buttonSel;
			break;
		}

		theSel = that.addInstanceIdTo( theSel );
		keyEls = source.querySelectorAll( theSel );

		if( keyEls.length <= 0 ) {
			if( initial ) {
				if( that.args.debug ) console.error( "FilterPosts: key element '" + keyElType + "' not found with selector '" + theSel + "'. The script will not work." );
			}
		} else if( keyEls.length > 1 ) {
			if( that.args.debug ) {
				console.warn( "FilterPosts: key element '" + keyElType + "' found in more than one copy with selector '" + theSel + "'. If you need several instances, use an instance ID and make sure each is unique. Elements found:" );
				console.log( keyEls );
			}
		} else {
			keyEl = keyEls[0];
		}

		return keyEl;
	};

	this.loadingStart = function() {
		that.loading = true;
		that.container.classList.add( that.args.loadingClass );

		nubeeLoading.loadingStart( that.container );

		/*if( that.button._loadingButton ) {
			that.button._loadingButton.loadingStart();
		} else {
			that.button.disabled = true;
		}*/
	};

	this.loadingEnd = function() {
		that.loading = false;
		that.container.classList.remove( that.args.loadingClass );

		nubeeLoading.loadingEnd( that.container );

		/*if( that.button._loadingButton ) {
			that.button._loadingButton.loadingEnd();
		} else {
			that.button.disabled = false;
		}*/
	};

	this.filterResult = async function() {
		let ajaxUrl = new URL( that.filterUrl );
		let fetchOptions = {
			headers: {
				'X-Requested-With': 'XmlHttpRequest'
			},
			method: 'GET',
		};

		that.loadingStart();
		
		if( that.args.debug ) console.log( "FilterPosts: Getting next page from URL '" + ajaxUrl.href + "'" );

		let response = await fetch( ajaxUrl.href, fetchOptions );
		let responseText = response.text().then( function( responseText ) {
			that.loadingEnd();
			return responseText;
		} );

		return responseText;
	};

	// On va chercher la prochaine page avec getNextPage, puis on fait des opérations de HTML pour appliquer les données reçues à la page actuelle
	this.showFilterResult = function() {
		window.smoothScroller.scrollTo({ target: that.container });

		that.filterResult().then( function( nextPageText ) {
			if( that.args.debug ) {
				console.log( "FilterPosts: Obtained next page as text:" );
				console.log( nextPageText );
			}

			// On va updater la querystring du browser pour que cette page soit accessible directement
			if( history.pushState ) {
				window.history.pushState( { path: that.filterUrl }, '', that.filterUrl );
			}

			let docFrag = new DocumentFragment();

			// On va chercher uniquement les éléments qui sont des enfants de cette instance d'infinite scroll. On modifie l'URL du bouton existant.
			that.extractPiecesFromText( nextPageText );

			if( that.args.debug ) {
				console.log( "FilterPosts: Parsed elements:" );
				console.log( that.nextPageItems );
			}

			that.container.dispatchEvent( beforeUpdateEvent );

			// On vide le contenu du container
			that.appendEl.innerHTML = '';

			// console.log(that);
			// console.log(that.container._infiniteScrollSimple);
			// that.container.classList.remove( that.args.noMoreClass );

			if( that.noMore === false ) {
				let issButton = that.container.querySelector('.iss-button');

				that.container.classList.remove( that.args.noMoreClass );

				if( issButton && that.nextPageButton ) {
					issButton.href = that.nextPageButton.href;
				}
			}

			// Si le container contient une instance de _infiniteScrollSimple on va la refresher pour que le AJAX recommence à s'éxécuter au cas où on a vu toute les pages du filtre d'avant.
			if( that.container._infiniteScrollSimple !== undefined ) {
				that.container._infiniteScrollSimple.refresh();
			}

			/*if( that.nextPageButton ) {
				// Il y a un bouton sur l'autre page. Il reste des pages.
				that.button.href = that.nextPageButton.href;
				if( that.args.debug ) console.log( "FilterPosts: Set next page button href to '" + that.button.href + "'" );
			} else {
				if( that.args.debug ) console.log( "FilterPosts: That was the last page." );
				that.noMore = true;
				that.container.classList.add( that.args.noMoreClass );
				that.container.dispatchEvent( noMoreEvent );
			}*/

			that.nextPageItems.forEach( function( thisEl ) {
				if( that.args.animate ) {
					thisEl.classList.add( that.args.animatingInClass );
				}

				docFrag.appendChild( thisEl );
			} );

			// Maintenant qu'on a rempli le docFrag, on peut l'ajouter au container.
			that.appendEl.appendChild( docFrag );


			// Mettons les bons src aux images (images lazy - src appliqués plus tard)
			if( 'function' == typeof imgCheck ) {
				imgCheck();
			}

			if( 'function' == typeof window.siteLazyLoad.update ) {
				window.siteLazyLoad.update();
			}

			// Animations. On force le reflow sinon la première s'animera jamais
			if( that.args.animate && that.nextPageItems ) {
				that.nextPageItems[0].offsetHeight;
				that.animateNewItems();
			}

			that.container.dispatchEvent( afterUpdateEvent );
		} );
	};

	this.extractPiecesFromText = function( sourceText ) {
		let htmlDoc = that.domParser.parseFromString( sourceText, 'text/html' );

		that.nextPageItems = htmlDoc.querySelectorAll(
			that.addInstanceIdTo( that.args.itemSel ) 
		);

		that.nextPageButton = that.getKeyElement( 'button', htmlDoc, false );
	};

	this.animateNewItems = function() {
		let prevEl = null;

		if( that.args.debug ) {
			console.log( "FilterPosts: Adding animation events to items:" );
			console.log( that.nextPageItems );
		}

		that.nextPageItems.forEach( function( thisEl ) {
			// Évènement custom pour dire que l'élément a fini d'apparaître
			thisEl.addEventListener( 'transitionend', function thisElAnimated( event ) {
				if( event.target.matches( that.args.itemSel ) ) {
					// if( that.args.debug ) {
					// 	console.log( "FilterPosts: transitionend fired on:" );
					// 	console.log( event.target );
					// }

					thisEl.dispatchEvent( itemAnimatedEvent, thisEl );
					thisEl.removeEventListener( 'transitionend', thisElAnimated );
				}
			} );

			if( prevEl ) {
				// On active tous sauf le premier quand le précédent a fini d'apparaître
				prevEl.addEventListener( 'issitemanimated', function prevElAnimated( event ) {
					// if( that.args.debug ) {
					// 	console.log( "FilterPosts: issitemanimated fired on previous item. Animating item:" );
					// 	console.log( thisEl );
					// }

					thisEl.classList.add( that.args.animatedInClass );
					event.target.removeEventListener( 'issitemanimated', prevElAnimated );
				} );
			}

			prevEl = thisEl;
		} );

		// On fait animer le premier pour amorcer la chaîne
		that.nextPageItems[0].classList.add( that.args.animatedInClass );
	};

	this.addEventListeners = function() {

		that.form.addEventListener( 'click', function( event ) {
			let checkboxClicked = event.target.closest( that.args.checkboxSel ),
				searchButtonClicked = event.target.closest( that.args.searchButton ),
				resetButtonClicked = event.target.closest( that.args.resetButton );

			if( searchButtonClicked || resetButtonClicked ) {
				event.preventDefault();
			}

			if( checkboxClicked || searchButtonClicked ) {
				// that.container.dispatchEvent( updateRequestedEvent );
				
				if( that.args.debug && checkboxClicked ) {
					console.log( 'The checkbox named "' + checkboxClicked.getAttribute('name') + '" has been clicked!' );
				}

				// Build QueryString
				let formData = new FormData( that.form );

				let xhrQueryString = '?',
					first = true,
					managedKeys = new Array();

				for( let fdKey of formData.keys() ) {
					if( managedKeys.indexOf( fdKey ) < 0 ) {
						managedKeys.push( fdKey );
						
						xhrQueryString += ( ! first ? '&' : '' ) + encodeURIComponent( fdKey ) + '=' + formData.getAll( fdKey );
						first = false;
					}
				}

				that.filterUrl = that.form.getAttribute('action') + xhrQueryString;

				// Si on a un bouton loadmore, on va updater son action
				if( document.querySelectorAll( that.args.buttonSel ).length > 0 ) {
					document.querySelectorAll( that.args.buttonSel ).forEach( function( thisBtn ) {
						let nextPageBaseURI;
						if( thisBtn.href.indexOf( '?' ) >= 0 ) {
							nextPageBaseURI = thisBtn.href.split('?')[0];
						} else {
							nextPageBaseURI = thisBtn.href;
						}

						thisBtn.href = nextPageBaseURI + xhrQueryString;
					});
				}

				that.showFilterResult();
			} else if( resetButtonClicked ) {
				event.preventDefault();

				that.form.reset();
				
				let checkboxes = document.querySelectorAll( that.args.checkboxSel );
				for( let i = 0; i < checkboxes.length; i++ ) {
					checkboxes[i].checked = false;
				}

				that.filterUrl = that.form.getAttribute('action');

				that.showFilterResult();
			}
		} );
	};

	// Initialisation

	this.init();

	if( ! this.container || ! this.form ) {
		return [];
	} else {
		this.container._FilterPosts = this;
	}
}

export default FilterPosts;
