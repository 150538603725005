// Fait paraître un gros loading avec un preloader.
// parent(HTMLElement) | défaut document.body
// preloaderSel | défaut '.footer-hidden-elements .preloader-facebook'

import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock/lib/bodyScrollLock.es6.js';

export function loadingStart( parent, preloaderSel ) {
	preloaderSel = preloaderSel || '.footer-hidden-elements .preloader-facebook';
	parent = parent || document.body;

	let loadingEl = parent.querySelector('.nubee-loading');

	if( ! loadingEl ) {
		let preloaderEl;
		let subLoadingEl;
		let labelEl;

		loadingEl = document.createElement( 'div' );
		loadingEl.classList.add( 'nubee-loading' );
		loadingEl.classList.add( 'admin-bar-offset' );
		subLoadingEl = document.createElement( 'div' );
		subLoadingEl.classList.add( 'nubee-loading-inner' );
		preloaderEl = document.querySelector( preloaderSel ).cloneNode( true );
		preloaderEl.classList.add( 'nubee-loading-preloader' );
		labelEl = document.createElement( 'div' );
		labelEl.classList.add( 'nubee-loading-preloader' );
		labelEl.textContent = window.bonesGlobals.i18n.functions.loading;

		subLoadingEl.append( preloaderEl, labelEl );
		loadingEl.append( subLoadingEl );
		parent.append( loadingEl );
	}

	// On force le reflow pour que la transition se fasse
	loadingEl.offsetWidth;

	// disableBodyScroll( loadingEl );
	document.documentElement.classList.add( 'nubee-loading-visible' );

	loadingEl.classList.add( 'visible' );
}


// Met fin au loading commencé par loadingStart

export function loadingEnd( parent ) {
	parent = parent || document.body;
	let loadingEl = parent.querySelector('.nubee-loading');

	// enableBodyScroll( loadingEl );
	document.documentElement.classList.remove( 'nubee-loading-visible' );

	if( loadingEl ) {
		loadingEl.classList.remove( 'visible' );
	}
}