/*** Alternative JS à srcset qui fonctionne aussi en background *************************************/
// Utiliser la classe img-check avec des attributs :
// data-src, data-src-small, data-src-medium, data-src-large

function imgCheck() {
	let imgSize;
	let mediumMinWidth;
	let largeMinWidth;

	document.querySelectorAll('.img-check').forEach( function( thisEl ) {
		//On peut spécifier les largeurs quand les sizes s'appliquent dans le HTML
		if( ! thisEl.getAttribute('data-medium-min-width') ) {
			mediumMinWidth = 699;
		} else {
			mediumMinWidth = parseFloat( thisEl.getAttribute('data-medium-min-width') );
		}

		if( ! thisEl.getAttribute('data-large-min-width') ) {
			largeMinWidth = 999;
		} else {
			largeMinWidth = parseFloat( thisEl.getAttribute('data-large-min-width') );
		}

		if( window.vWidth <= mediumMinWidth ) {
			imgSize = 'small';
		} else if ( window.vWidth <= largeMinWidth ) {
			imgSize = 'medium';
		} else {
			imgSize = 'large';
		}

		// console.log( 'imgCheck : Choosing size "' + imgSize + '"' );

		let newSrc = thisEl.getAttribute('data-src-' + imgSize);

		if( newSrc ) {
			thisEl.setAttribute('data-src', newSrc);

			if( thisEl.getAttribute('data-original') ) {
				thisEl.setAttribute('data-original', newSrc);
			}

			if( thisEl.matches('img') ) {
				if( thisEl.getAttribute('src') ) {
					thisEl.setAttribute('src', newSrc);
				}
			} else {
				if( thisEl.style.backgroundImage ) {
					thisEl.style.backgroundImage = 'url(\'' + newSrc + '\')';
				}
			}
		}

		// Changer les valeurs de width et height
		if( thisEl.getAttribute( 'data-width-' + imgSize) ) {
			thisEl.setAttribute( 'width', thisEl.getAttribute('data-width-' + imgSize) );

			if( thisEl.getAttribute( 'data-height-' + imgSize) ) {
				thisEl.setAttribute( 'height', thisEl.getAttribute('data-height-' + imgSize) );
			} else {
				thisEl.removeAttribute( 'height' );
			}
		}

	} );
}

export default imgCheck;